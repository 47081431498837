<template>
  <div>
    <div class="text-center">
      <br />
      <div class="image-container">
        <img
          src="/images/logo.png"
          alt="Denay Security System"
          style="height: 200px; width: auto"
        />
      </div>
    </div>
    <div class="wrapper text-center">
      <svg>
        <text
          x="50%"
          y="50%"
          dy=".35em"
          text-anchor="middle"
          style="font-size: 25px"
        >
          {{ title }}
        </text>
      </svg>
    </div>
    <span v-html="description"></span>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import Swal from 'sweetalert2';
import { moduleSetting } from '../helper/constants';
export default {
  name: 'Home',
  data() {
    return {
      title: 'DOOR SECURITY',
      description: '',
    };
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      Swal.fire({
        title: 'DENAY SECURITY SYSTEM',
        text: `Silahkan install aplikasi DOOR SECURITY !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#a60957',
        cancelButtonColor: '#545050',
        confirmButtonText: 'Ya, Install!',
      }).then((result) => {
        if (result.isConfirmed) {
          this.deferredPrompt.prompt();
        }
      });
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    });
  },
  mounted() {
    this.getSettingProfile({
      module: moduleSetting.module_profile,
    });
  },
  watch: {
    item: function () {
      if (!Object.keys(this.item).length) return;
      this.setDataProfile();
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.setting.isLoading,
      item: (state) => state.setting.item,
    }),
  },
  methods: {
    ...mapActions('setting', ['getSettingProfile']),

    setDataProfile() {
      const data = this.item;
      this.description = data.description;
    },
  },
};
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');

svg {
  font-family: 'Russo One', sans-serif;
  width: 100%;
  height: 100%;
}
svg text {
  animation: stroke 3s infinite alternate;
  stroke-width: 2;
  stroke: #231aa0;
  font-size: 25px;
}
@keyframes stroke {
  0% {
    fill: rgba(27, 86, 204, 0);
    stroke: rgb(130, 3, 62);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(27, 86, 204, 0);
    stroke: rgb(130, 3, 62);
  }
  80% {
    fill: rgba(27, 86, 204, 0);
    stroke: rgb(130, 3, 62);
    stroke-width: 3;
  }
  100% {
    fill: rgb(204, 27, 127);
    stroke: rgba(35, 26, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

.wrapper {
  background-color: #ffffff;
}
</style>
